$(function () {
  // Scroll Hint
  new ScrollHint('.table-scroll-wrapper', {
    i18n: {
      scrollable: 'スクロールできます',
    },
  });
});

$(function () {
  const smoothScroll = (target) => {
    const speed = 300;
    const easing = 'swing';
    const navigationHeight = $('header.construct-header').innerHeight();
    const position = target.offset().top - navigationHeight - 50;
    $('html, body').animate({ scrollTop: position }, speed, easing);
    return false;
  };

  let pageHash = window.location.hash || location.hash;
  if (pageHash) {
    let target = $(pageHash);
    if (!target.length) return;
    $('html, body').stop().scrollTop(0);
    history.replaceState('', document.title, window.location.pathname);
    smoothScroll(target);
  }

  $('a[href*="#"]').on('click', function () {
    const target = $(this.hash === '#' || '' ? 'html' : this.hash);
    if (!target.length) return;
    smoothScroll(target);
    return false;
  });
});
